import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "currencyField" ]

  connect() {
   this.formatCurrencyFields()
  }

  formatCurrencyFields() {
    this.currencyFieldTargets.forEach((currencyField) => {
      let cents = currencyField.dataset.cents
      let currency = currencyField.dataset.centsCurrency
      let plusSignForPositiveValues = currencyField.dataset.centsPlusSignForPositiveValues === 'true'
      let maximumFractionDigits = currencyField.dataset.centsRoundToInteger === 'true' ? 0 : 2
      if(currencyField.dataset.centsHideZero !== 'true' || cents != 0) {
        let operator;
        if(cents > 0 && plusSignForPositiveValues) {
          operator = '+'
        } else if(cents < 0) {
          operator = '-'
        } else {
          operator = ''
        }
        let formattedCents = (parseFloat(Math.abs(cents)) / 100).toLocaleString('en-US', { maximumFractionDigits }).replaceAll(',', "'")
        let targetElement = currencyField.dataset.numberFormattingChild === undefined ? currencyField : currencyField.querySelector(currencyField.dataset.numberFormattingChild)
        targetElement.innerText = `${currency} ${operator}${formattedCents}`
      }
    })
  }
}
