var Trix = require("trix");

// Trix Custom buttons
Trix.config.blockAttributes.h1 = {
  tagName: 'h1',
  breakOnReturn: true,
  parse: false
}
Trix.config.blockAttributes.h2 = {
  tagName: 'h2',
  breakOnReturn: true,
  parse: false
}
Trix.config.textAttributes.textLeft = {
  tagName: 'text-left',
}
Trix.config.textAttributes.textCenter = {
  tagName: 'text-center',
}
Trix.config.textAttributes.textRight = {
  tagName: 'text-right',
}
/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
  var buttonHtmls = []
  buttonHtmls.push('<button class="trix-button" type="button" data-trix-attribute="h1">H1</button>')
  buttonHtmls.push('<button class="trix-button" type="button" data-trix-attribute="h2">H2</button>')

  buttonHtmls.push('<button class="trix-button" type="button" data-trix-attribute="textLeft"><i class="fa fa-align-left"></i></button>')
  buttonHtmls.push('<button class="trix-button" type="button" data-trix-attribute="textCenter"><i class="fa fa-align-center"></button>')
  buttonHtmls.push('<button class="trix-button" type="button" data-trix-attribute="textRight"><i class="fa fa-align-right"></button>')

  buttonHtmls.forEach((e) => {
    event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", e)
  })

  // allow relative urls in trix links
  var linkInput = event.target.toolbarElement.querySelector("input[name=href]")
  linkInput.type = "text"
  linkInput.pattern = "(https?://|/).+"
})
