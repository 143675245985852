import { Controller } from "@hotwired/stimulus"
import { defaultLocale } from "i18n-js"

export default class extends Controller {
  static targets = [ "amountCell", "row" ]

  connect() {
   this.accountGroupCells = this.amountCellTargets.filter((cell) => cell.dataset.level === "accountGroup")
   this.accountCells = this.amountCellTargets.filter((cell) => cell.dataset.level === "account")
   this.subAccountCells = this.amountCellTargets.filter((cell) => cell.dataset.level === "subAccount")
   this.templateSumCells= this.amountCellTargets.filter((cell) => cell.dataset.level === "templateSum")

   this.calculateAccountTotals()
   this.calculateAccountGroupTotals()
   this.calculateTemplateSumTotals()
   this.calculateComparisonOptions()
   this.applyBackgroundColorToRows()
  }

  calculateComparisonOptions() {
    let comparisonCells = this.amountCellTargets.filter((cell) => cell.dataset.sourceType === "comparison" && cell.dataset.isDisplayingData === "true")
    let targetCells = this.amountCellTargets.filter((cell) => ['round', 'tag'].includes(cell.dataset.sourceType))
    comparisonCells.forEach((comparisonCell) => {
      let comparisonData = JSON.parse(comparisonCell.dataset.sourceId)
      let selectedTargetCells = targetCells.filter((cell) => {
        return cell.dataset.accountGroupId === comparisonCell.dataset.accountGroupId &&
        cell.dataset.accountId === comparisonCell.dataset.accountId &&
        cell.dataset.subAccountId === comparisonCell.dataset.subAccountId &&
        cell.dataset.templateSumId === comparisonCell.dataset.templateSumId
      })

      let leftTargetCell = selectedTargetCells.find((cell) => {
        return cell.dataset.sourceType === comparisonData.left.type &&
        parseInt(cell.dataset.sourceId) === comparisonData.left.id
      })

      let rightTargetCell = selectedTargetCells.find((cell) => {
        return cell.dataset.sourceType === comparisonData.right.type &&
        parseInt(cell.dataset.sourceId) === comparisonData.right.id
      })

      if (leftTargetCell && rightTargetCell) {
        let absoluteDifference = parseInt(rightTargetCell.dataset.cents) - parseInt(leftTargetCell.dataset.cents)
        let relativeDifference = absoluteDifference / parseInt(leftTargetCell.dataset.cents) * 100
     
        let thresholdPercentage = parseInt(comparisonCell.dataset.highlightingThresholdPercentage)
        let positiveTextColor;
        let positiveBgColor;
        let negativeTextColor;
        let negativeBgColor;

        if(comparisonCell.dataset.inverseThresholdHighlighting === "true") {
          positiveTextColor = '#D73C31'
          positiveBgColor ='#F7DCE0'
          negativeTextColor = '#4FA736'
          negativeBgColor = '#D6E8D7'
        } else {
          positiveTextColor = '#4FA736'
          positiveBgColor = '#D6E8D7'
          negativeTextColor = '#D73C31'
          negativeBgColor ='#F7DCE0'
        }

        comparisonCell.dataset.cents = absoluteDifference
        let operator = '';
        if(relativeDifference == Infinity || relativeDifference == -Infinity || isNaN(relativeDifference)) {
          relativeDifference = 0
        } else {
          if(relativeDifference > 0) {
            operator = '+'
          } else if(relativeDifference< 0) {
            operator = '-'
          }
        comparisonCell.querySelector('.percentage-field').innerText = `${operator}${Math.abs(relativeDifference).toLocaleString('en-US', { maximumFractionDigits: 0 }).replaceAll(',', "'")}%`
        }
        if(comparisonCell.dataset.level == "account" || comparisonCell.dataset.level == "subAccount") {

        if(relativeDifference > 0) {
          comparisonCell.style.color = positiveTextColor
        if(relativeDifference > thresholdPercentage ) {
          comparisonCell.style.backgroundColor = positiveBgColor
        }
        }
        if(relativeDifference < 0) {
          comparisonCell.style.color = negativeTextColor
        if(relativeDifference < thresholdPercentage * -1) {
          comparisonCell.style.backgroundColor = negativeBgColor
        }
        }
      }
      }
    })
  }

  calculateAccountTotals() {
    this.accountCells.forEach((accountCell) => {
    let selectedSubAccountCells = this.subAccountCells.filter((subAccountCell) => { 
      return subAccountCell.dataset.accountId === accountCell.dataset.accountId && 
      subAccountCell.dataset.sourceType === accountCell.dataset.sourceType &&
      subAccountCell.dataset.sourceId === accountCell.dataset.sourceId
    })

    let sum =  selectedSubAccountCells
    .map((cell) => parseInt(cell.dataset.cents))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    accountCell.dataset.cents = sum
  })
  }

  calculateAccountGroupTotals() {
    this.accountGroupCells.forEach((accountGroupCell) => {
      let selectedAccountCells = this.accountCells.filter((accountCell) => {
        return accountCell.dataset.accountGroupId === accountGroupCell.dataset.accountGroupId &&
        accountCell.dataset.sourceType === accountGroupCell.dataset.sourceType &&
        accountCell.dataset.sourceId === accountGroupCell.dataset.sourceId
      })

    let sum =  selectedAccountCells
    .map((cell) => parseInt(cell.dataset.cents))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    accountGroupCell.dataset.cents = sum
    })
  }

  calculateTemplateSumTotals() {
    this.templateSumCells.forEach((templateSumCell) => {
    let positiveAccountGroupCells = this.accountGroupCells.filter((accountGroupCell) => {
      return templateSumCell.dataset.positiveAccountGroupIds.includes(accountGroupCell.dataset.accountGroupId) &&
      templateSumCell.dataset.sourceType === accountGroupCell.dataset.sourceType &&
      templateSumCell.dataset.sourceId === accountGroupCell.dataset.sourceId &&
      accountGroupCell.dataset.isDisplayingData === "true"
    })

    let negativeAccountGroupCells = this.accountGroupCells.filter((accountGroupCell) => {
      return templateSumCell.dataset.negativeAccountGroupIds.includes(accountGroupCell.dataset.accountGroupId) &&
      templateSumCell.dataset.sourceType === accountGroupCell.dataset.sourceType &&
      templateSumCell.dataset.sourceId === accountGroupCell.dataset.sourceId &&
      accountGroupCell.dataset.isDisplayingData === "true"
    })

    let positiveSum =  positiveAccountGroupCells
    .map((cell) => parseInt(cell.dataset.cents))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    let negativeSum =  negativeAccountGroupCells
    .map((cell) => parseInt(cell.dataset.cents))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    templateSumCell.dataset.cents = positiveSum - negativeSum
  })
  }

  applyBackgroundColorToRows() {
    this.rowTargets.filter((row) => {
      return row.dataset.level == 'account'
    }).forEach((row, index) => {
      const isEvenRow = index % 2 === 0;
      row.classList.toggle("light-row", isEvenRow);
      row.classList.toggle("dark-row", !isEvenRow);

      this.rowTargets.filter((subrow) => {
        return subrow.dataset.collapsibleParentIdentifier == row.dataset.collapsibleIdentifier
      }).forEach((subrow, subindex) => {
        const isEvenRow = subindex % 2 === 0;
        subrow.classList.toggle("dark-row", isEvenRow);
        subrow.classList.toggle("light-row", !isEvenRow);
      })
    })
  }

  emptyRows() {
    return this.rowTargets.filter((row) => {
      let amountCells = [...row.querySelectorAll("[data-cents]")]
      return amountCells.every((cell) => cell.dataset.cents === "0")
    })
  }
}
