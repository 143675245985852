import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="finance-invoice-item-template-form-companies"
export default class extends Controller {
  connect() {}

  initialize() {
    $('#finance_invoice_item_template_company_id').on('change', () => {
      this.updateAccounts();
    })
  }

  updateAccounts() {
    const selectedCompanyId = this.element.value;
    const url = `${this.element.dataset.url}?company_id=${selectedCompanyId}`

    fetch(url, { headers: { Accept: 'text/vnd.turbo-stream.html' } })
    .then(response => response.text())
    .then(html => { Turbo.renderStreamMessage(html); })
    .finally(() => {
      setTimeout(() => {
        callChosenJS(false);
      }, 10);
    });
  }
}
