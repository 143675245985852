import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "element" ]
  static values = {
    open: Boolean,
    callback: String
  }

  connect() {
    this.updateView()
  }

  toggle() {
    this.openValue = !this.openValue
    this.updateView()
    this.callCallback()
  }

  descendantElements(parentIdentifier, onlyDirectChildren = false) {
    if(!parentIdentifier || parentIdentifier == "") return []
    let childSelector = `[data-collapsible-parent-identifier='${parentIdentifier}']`
    let children = document.querySelectorAll(childSelector)
    let descendants = children

    if(!onlyDirectChildren) {
    children.forEach((child) => {
      descendants = [...descendants].concat([...this.descendantElements(child.dataset.collapsibleIdentifier)])
    })
    }

    return descendants
  }

  updateView() {
    this.hideCollapsibleBodies()
    this.showCollapsibleBodies()
    this.updateToggleIcons()
  }

  hideCollapsibleBodies() {
    this.descendantElements(this.elementTarget.dataset.collapsibleIdentifier).forEach((child) => {
      if (!this.openValue) {
        child.classList.add("d-none")
      }
    })
  }

  showCollapsibleBodies() {
    this.descendantElements(this.elementTarget.dataset.collapsibleIdentifier, true).forEach((child) => {
      if (this.openValue) {
        child.classList.remove("d-none")
      }
    })
  }

  updateToggleIcons() {
    let iconParent = this.elementTarget.querySelector(".collapsible-toggle-icon")
    if(this.elementTarget.dataset.collapsibleIdentifier == "") {
      iconParent.innerHTML = ''
    }
    else if(this.openValue) {
      iconParent.innerHTML = '<i class="fa fa-chevron-up"></i>'
    } else {
      iconParent.innerHTML = '<i class="fa fa-chevron-down"></i>'
    }
  }

  callCallback() {
    if (!!this.callbackValue) {
      window[this.callbackValue]();
    }
  }
}
