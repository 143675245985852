import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="finance-sub-accounts-from-account"
export default class extends Controller {
  connect() {}

  initialize() {
    $(this.element).on('change', () => {
      this.updateSubAccounts();
    })
  }

  updateSubAccounts() {
    const selectedAccountId = this.element.value;
    const url = `${this.element.dataset.url}?account_id=${selectedAccountId}&target=${this.element.dataset.target}&unique_id=${this.element.dataset.uniqueId}&name=${this.element.name}`

    fetch(url, { headers: { Accept: 'text/vnd.turbo-stream.html' } })
    .then(response => response.text())
    .then(html => { Turbo.renderStreamMessage(html); })
    .finally(() => {
      setTimeout(() => {
        callChosenJS(false);
      }, 10);
    });
  }
}
