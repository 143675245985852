// Entry point for the build script in your package.json

import "./src/jquery"

import "./src/I18n"

//require.context('../images/', true)
require("@rails/ujs").start()
require("@rails/activestorage").start()
import("./src/channels")
require("@rails/actiontext")

import * as idb from 'idb-keyval';
window.idb = idb;

import 'jquery-number'
import 'mark.js'
import "popper.js"
import "bootstrap"
import "chosen-js/chosen.jquery.min"

import alertify from "alertifyjs/build/alertify.min"
import {Timeline as VisTimeline} from 'vis-timeline/standalone/umd/vis-timeline-graph2d.min'
import {Network as VisNetwork} from 'vis-network/standalone/umd/vis-network.min'
import {DataSet as VisDataSet} from 'vis-data/standalone/umd/vis-data.min'
// needed for order/article_groups#map
import {DataSet as VisDataSetLegacy, Network as VisNetworkLegacy} from 'vis/dist/vis.min'

import 'dropzone'
import 'blueimp-file-upload'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import Sortable from "sortablejs";
import moment from "moment"
import 'moment/locale/de'
moment.locale('de')
window.moment = moment;

import flatpickr from "flatpickr"
import { German } from "flatpickr/dist/l10n/de"
import { cocoonInitializer } from '@nathanvda/cocoon'
import "@hotwired/turbo-rails"
import 'mark.js/dist/jquery.mark.min'

import Highcharts from 'highcharts';
window.Highcharts = Highcharts;

window.jQuery = $;
window.$ = $;
import autosize from "autosize";

window.autosize = autosize;

window.visNetwork = VisNetwork
window.visTimeline = VisTimeline;
window.visDataSet = VisDataSet

window.visNetworkLegacy = VisNetworkLegacy
window.visDataSetLegacy = VisDataSetLegacy

// window.visNetworkLegacy = vis

window.alertify = alertify;
window.Sortable = Sortable;
window.I18n = I18n;


Dropzone.autoDiscover = false;
window.ShortcutButtonsPlugin = ShortcutButtonsPlugin

import "./controllers"
import "./src/trix"
